import * as React from "react";
// import Slider from "react-slick";
import Layout from "../components/layout";
import { graphql } from "gatsby";
// import Img from "gatsby-image"
import Title from "../components/title";

const CarLashingEn = ({ data }) => {
  const pageData = {
    titleClass: "car_lashing",
    title: "CAR LASHING",
    discription: "",
  };
  const seodata = {
    title:
      "This is a brand new type of car lashing that comes with an original twisted hook developed by AVIELAN",
    seo: {
      description:
        "This is a brand new type of car lashing that comes with an original twisted hook developed by AVIELAN.The twisted hook helps ensure safer, more secure lashing than ever before.",
    },
    image: {
      url: data.datoCmsImage.topimage[21].url,
    },
    slug: "contact",
  };
  // const newsData = data.allDatoCmsNews.nodes

  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="car_lashing">
        <p className="language_switching">
          <a href="/car-lashing">日本語はコチラ</a>
        </p>
        <div className="contents_top">
          <div className="main_title">
            <div className="title01">
              <p className="logoimg">
                <img
                  src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_logo.png"
                  alt="car lashing"
                />
              </p>
              <h1>CAR LASHING</h1>
              <h2>
                This is a brand new type of car lashing that comes with an
                original twisted hook developed by AVIELAN.The twisted hook
                helps ensure safer, more secure lashing than ever before.
              </h2>
              <p>
                <span className="mark1">Hook</span>
              </p>
            </div>
            <div className="main_circle">
              <h3>
                The world' first patented
                <br />
                <span>「twisted hook!」</span>
                <br />
              </h3>
              <p>
                U.S. patent number : US 10988072 B2
                <br />
                Japanese patent number : 6216484
                <br />
                Korean patent number : 10-2250109
              </p>
            </div>
          </div>
          <div className="mainimg">
            <img
              src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_A1.png"
              alt="car lashing"
            />
            <div className="img_comment">
              <p className="title3">
                The twist makes it possible to be lashed safer and more secure.
              </p>
            </div>
          </div>
        </div>
        <div className="detail_box">
          <div className="item_detail">
            <div className="datail_comment">
              <p className="title">Feature 1</p>
              <p className="text_size2 text_w">The twist secures </p>
              <p className="text_size1 text_red text_w">
                the hook against shaking and vibrations!
              </p>
              <p className="text_size3 text_w">
                Our innovative twisted hook is designed to only be removed by
                first twisting it diagonally.
              </p>
              <p className="text_size4">
                The dramatic twist at the end of the hook is clear when viewing
                the hook from above while using it for lashing. The twisted part
                of the hook catches on the eye bolt even if the hook is shaken
                or vibrated up or down during transportation, making the hook
                far less likely to come off.
              </p>
            </div>
            <div className="datail_img">
              <p className="title2">
                State of the hook while it is attached to an eye bolt, etc. from
                above
              </p>
              <p className="img_hook">
                <span className="mark1">Appearance from above</span>
                <img
                  src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_A2.png"
                  alt="car lashing"
                />
              </p>
              <p className="img_hook">
                <span className="mark1">Appearance from the side</span>
                <img
                  src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_A3.png"
                  alt="car lashing"
                />
              </p>
            </div>
          </div>
          <div className="item_detail">
            <div className="datail_comment">
              <p className="title">Feature 2</p>
              <p className="text_size2 text_w">Thanks to the twist,</p>
              <p className="text_size1 text_red text_w">
                the hook can also be attached from below!
              </p>
              <p className="text_size3 text_w">
                In general, our twisted hook must be attached from above, but
                the new hook is able to attached to the lashing target from
                below. In case that you have to do that - the twist helps to
                prevent the hook from coming off.
              </p>
              <p>
                <img
                  src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_A4.jpg"
                  alt="car lashing"
                />
              </p>
              <p className="text_size4">
                <span className="mark1 text_size5">
                  Hook attached from below
                </span>
                State of the webbing when it is suitably tense
              </p>
            </div>
            <div className="datail_img">
              <p className="title2">When the webbing becomes slack</p>
              <div className="datail_img2">
                <p>
                  <img
                    src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_A6.jpg"
                    alt="car lashing"
                  />
                </p>
                <p>
                  The spiral bend of the hook (enclosed by a red circle)
                  temporarily stops the hook from moving.
                </p>
              </div>
              <div className="datail_img2">
                <p>
                  <img
                    src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_A5.jpg"
                    alt="car lashing"
                  />
                </p>
                <p>
                  Additionally, the weight of the part enclosed by a blue circle
                  causes the webbing side of the hook to swing down, which keeps
                  the hook attached.
                </p>
              </div>
            </div>
          </div>
          <div className="detail_attention">
            <p className="text_size4">
              <span className="text_size5 mark2">Note</span>Be sure to securely
              attach the hook when using it (as shown by the feature 1 images).
              If the hook is not securely attached, there is a risk of it coming
              off or not working as well as it is supposed to.
            </p>
          </div>
        </div>
        <div className="contents_top contents_top2">
          <div className="main_title">
            <div className="title01">
              <div className="title_spec">
                <p className="title3 text_size1">Tougher and safer</p>
                <p className="text_size5">
                  Breaking load of a conventional set
                  <span>2.0t</span>
                </p>
                <p className="text_size5">
                  Breaking load of AVIELAN's car lashing set
                  <span>3.0t</span>
                </p>
              </div>
              <h2>The new car lashing is really safe.</h2>
              <p>
                <span className="mark1">Buckle</span>
              </p>
            </div>
          </div>
          <div className="mainimg">
            <img
              src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_B1.png"
              alt="car lashing"
            />
            <div className="img_comment">
              <div className="spec">
                <p>Specifications</p>
                <p>Webbing width: 25 mm (0.98 in)</p>
                <p>Webbing length: 1.4 m (4ft7in)</p>
                <p>Total weight: approximately 840 g (1lb14oz)</p>
              </div>
              <div className="spec">
                <p>Breaking load (BL)</p>
                <p>Set breaking load: 3 t (6,615 lb)</p>
              </div>
            </div>
          </div>
        </div>
        <div className="detail2">
          <div className="detail2_box_waku">
            <div className="detail2_box">
              <div className="w50">
                <p className="text_size2 title3">
                  High guide in which the webbing easily fits
                </p>
                <p className="text_size2 text_red text_w">
                  The shape is designed so that the belt fits easily, which
                  reduces the load when using the lashing.
                </p>
              </div>
              <div className="w50">
                <img
                  src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_B3.jpg"
                  alt="car lashing"
                />
              </div>
            </div>
          </div>

          <div className="detail2_box_waku">
            <div className="detail2_box">
              <div className="w100 row">
                <img
                  src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_B8.png"
                  alt="car lashing"
                />
              </div>
              <div className="w100">
                <p className="text_size2 title3">Two layers of safety</p>
                <p className="text_size2 text_red text_w">
                  In addition to the arm locking system, our car lashing has a
                  belt stopper to reduce the risk of the buckle unfastening.
                </p>
              </div>
            </div>
          </div>

          <div className="detail2_box_waku">
            <div className="detail2_box">
              <div className="w100 row">
                <p className="text_size2 title3">
                  Stainless-steel plate to ensure a long-lasting webbing
                </p>
              </div>
              <div className="w50">
                <p className="text_size2 text_red text_w">
                  The stainless-steel plate is unlikely to rust, which means
                  less risk of rust rubbing against the webbing and wearing it
                  out during use.
                </p>
              </div>
              <div className="w50">
                <img
                  src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_B4.jpg"
                  alt="car lashing"
                />
              </div>
            </div>
          </div>
          <div className="detail2_box_waku">
            <div className="detail2_box">
              <div className="w100 row">
                <p className="text_size2 title3">
                  Soft surface coating to prevent damage
                </p>
              </div>
              <div className="w100">
                <p className="text_size2 text_red text_w">
                  The buckle clip and hook have a surface coating, so you don't
                  have to worry about them damaging parts the car lashing is
                  lashed to.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="contents_top3">
          <div className="mainimg">
            <img
              src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_B2.png"
              alt="car lashing"
            />
          </div>
        </div>
        <div className="detail2">
          <div className="detail2_box_waku">
            <div className="detail2_box">
              <div className="w100">
                <p className="text_size2 title3">
                  Limit line for peace of mind
                </p>
                <p className="text_size5">
                  The red thread line on the inside of the webbing shows that
                  the webbing has reached its limit. If the red thread is
                  exposed due to damage, please replace the webbing. Be sure to
                  regularly inspect the car lashing, and always inspect the
                  lashing before using it.
                </p>
              </div>
              <div className="w100">
                <img
                  src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_B9.png"
                  alt="car lashing"
                />
              </div>
            </div>
          </div>

          <div className="detail2_box_waku">
            <div className="detail2_box">
              <div className="w100">
                <p className="title4">option</p>
                <p className="text_size2 title3">
                  Optional strap for where it’s difficult to attach the hook.
                </p>
                <p className="text_size5">
                  If it’s difficult to attach the hook, you need this optional
                  strap. Because the strap is directly attached to the webbing,
                  there is no need to worry about theft or loss.
                </p>
              </div>
              <div className="w100">
                <img
                  src="https://cdn.shopify.com/s/files/1/0610/1994/7144/files/car_lashing_B7.png"
                  alt="car lashing"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact_">
        <div className="tel_contact">
          <p>
            <span>Inquiries by email</span>
          </p>
          <p>contact</p>
          <p>
            We are basically closed on Saturdays, Sundays and public holidays.{" "}
          </p>
        </div>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/thanks/"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />

          <div className="form-group">
            <label>
              <span>
                Company<b>※</b>
              </span>
              <input
                type="text"
                className="form-control"
                id="company"
                name="company"
                placeholder="Company"
                maxLength="30"
                minLength="2"
                required
              />
            </label>

            <label>
              <span>Department name</span>
              <input
                type="text"
                className="form-control"
                id="div-name"
                name="div-name"
                placeholder="Department name"
                maxLength="30"
                minLength="2"
              />
            </label>

            <label>
              <span>
                name<b>※</b>
              </span>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="name"
                maxLength="30"
                minLength="2"
                required
                autoComplete="name"
              />
            </label>

            <label>
              <span>
                email<b>※</b>
              </span>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder=""
                pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                required
                autoComplete="email"
              />
            </label>

            <label>
              <span>TEL</span>
              <input
                type="text"
                className="form-control"
                id="telnum"
                name="telnum"
              />
            </label>

            <label>
              <span>
                Contents of inquiry<b>※</b>
              </span>
              <textarea
                className="form-control"
                id="contact"
                name="content"
                rows="10"
                required
              ></textarea>
            </label>
          </div>

          <div className="form-btn">
            <button type="submit">SEND</button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CarLashingEn;

export const query = graphql`
  query {
    allDatoCmsNews {
      nodes {
        title
        data(formatString: "YYYY/MM/DD")
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        fluid(
          maxWidth: 1800
          forceBlurhash: false
          imgixParams: { auto: "compress", fit: "crop" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
